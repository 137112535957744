import * as React from "react"
import * as styles from "./style.module.scss"
import Heading from "../../images/lineUp/heading.svg"
import ViewMore from '../../images/lineUp/viewmore.svg'
import Image01 from '../../images/lineUp/image01.jpg'
import Image02 from '../../images/lineUp/image02.jpg'
import { Link } from "gatsby"

// data
const heading = {
  heading: 'line up',
  main: '物件一覧'
}

// markup
const LineUp = () => {
  return (
    <div className={styles.lineUp}>
      <div className={styles.headings}>
        <div className={styles.heading}>
          <img src={Heading} alt={heading.heading} />
        </div>
        <h2 className={styles.title}>
          {heading.main}
        </h2>
      </div>
      
      <div className={styles.lineUpInner}>
        <div className={styles.item}>
          <div className={styles.itemImageWrapper}>
            <div className={styles.itemImageClosed}>
              <img src={Image01} alt="" />  
            </div>
          </div>
          <Link to="/akasaka" className={styles.button} >
            <span>赤坂</span>
            <div className={styles.viewMore}>
              <img src={ViewMore} alt="" />  
            </div>
          </Link>
        </div>
        <div className={styles.item}>
          <div className={styles.itemImageWrapper}>
            <div className={styles.itemImageClosed}>
              <img src={Image02} alt="" />  
            </div>
          </div>
          <Link to="/jimbocho" className={styles.button} >
            <span>神保町</span>
            <div className={styles.viewMore}>
              <img src={ViewMore} alt="" />  
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default LineUp
