import * as React from "react"
import ReactHtmlParser from "react-html-parser"
import * as styles from "./style.module.scss"
import Heading from "../../images/contact/heading.svg"
import IconContactUs from "../IconContactUs/index"

// data
const text = {
  heading: 'contact',
  main: '内覧・お問い合わせ',
  text: {
    pc: `EFFICEでのご入居をお考えの方、詳細を知りたい方は\nお気軽にご相談ください。`,
    sp: `EFFICEでのご入居をお考えの方、\n詳細を知りたい方は\nお気軽にご相談ください。`,
  }
}

const link = {
  text: '内覧・お問い合わせはこちら',
  url: 'https://docs.google.com/forms/d/e/1FAIpQLSds7NhBbFIhNvCuE1i2D6JtfdyHpzDSYNnrzCyslXhuibdFKw/viewform',
}

type Props = {
  isDesktop: boolean
}

// markup
const Contact = (props: Props) => {
  const [isHover, setIsHover] = React.useState(false);
  return (
    <div className={styles.contact}>
      <div className={styles.contactInner}>
        <div className={styles.content}>
          <div className={styles.headings}>
            <div className={styles.heading}>
              <img src={Heading} alt={text.heading} />
            </div>
            <h2 className={styles.title}>
              {text.main}
            </h2>
          </div>
          <p className={styles.text}>{props.isDesktop ? ReactHtmlParser(text.text.pc) : ReactHtmlParser(text.text.sp)}</p>
        </div>
        <a
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
          href={link.url}
          target="_blank"
          className={styles.button}
        >
          <IconContactUs width={48} height={40} color={props.isDesktop ? isHover ? '#6D7873' : '#fff' : '#fff'} />
          <span>{link.text}</span>
        </a>
      </div>
    </div>
  )
}

export default Contact
