import * as React from "react";
import IconContactUs from "../IconContactUs/index";
import * as styles from "./style.module.scss";
import LogoImage from "../../images/logo.svg";
import { Link } from "gatsby";

// data
const link = {
  desktopText: "内覧・お問い合わせ",
  mobileText: "お問い合わせ",
  url: "https://docs.google.com/forms/d/e/1FAIpQLSds7NhBbFIhNvCuE1i2D6JtfdyHpzDSYNnrzCyslXhuibdFKw/viewform",
};

const icon = {
  desktop: {
    width: 72,
    height: 62,
  },
  mobile: {
    width: 32,
    height: 26.8,
  },
  color: "#B3BAB7",
};

type Props = {
  isDesktop: boolean;
  hidden: boolean;
};

// markup
const Header = (props: Props) => {
  const [scrollY, setScrollY] = React.useState(0);
  const handleScroll = () => {
    setScrollY(window.scrollY);
  };
  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className={props.hidden ? styles.headerHidden : styles.header }>
      <Link to="/" className={styles.logo}>
        <span>
          <img src={LogoImage} alt="EFFICE" />
        </span>
      </Link>
      <a
        href={link.url}
        target="_blank"
        className={scrollY > 50 ? styles.contactUsMinified : styles.contactUs}
      >
        <div>
          <IconContactUs
            width={props.isDesktop ? icon.desktop.width : icon.mobile.width}
            height={props.isDesktop ? icon.desktop.height : icon.mobile.height}
            color={icon.color}
          />
        </div>
        <span className={styles.contactUsText}>
          {props.isDesktop ? link.desktopText : link.mobileText}
        </span>
      </a>
    </div>
  );
};

export default Header;
