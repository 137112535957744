import * as React from "react"
import { Helmet } from "react-helmet-async"

import ogImage from "../../../static/images/ogp.png"

// data
const meta = {
  title: 'EFFICE | 人が集まる、生産性が上がるワンランクアップなオフィス',
  description: 'EFFICEは成長する企業を支える新しいコンセプトのオフィス。人の採用やチームの結束力、業務の効率性を高める効果だけでなく、環境負荷を削減できるよう設計。',
  url: 'https://effice.jp',
  image: 'https://effice.jp' + ogImage,
  type: 'website'
}

type Props = {
  title: string,
  description: string,
  path: string
}

// markup
const Seo = (props: Props) => {
  return (
    <Helmet>
        <title>{props.title}</title>
        <head prefix="og: http://ogp.me/ns#" />
        <meta property="og:site_name" content={props.title} />
        <meta property="og:title" content={props.title} />
        <meta
          name="description"
          content={props.description}
        />
        <meta property="og:description" content={props.description} />
        <meta property="og:url" content={meta.url + props.path} />
        <meta property="og:image" content={meta.image} />
        <meta property="og:type" content={meta.type} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={props.title} />
        <meta name="twitter:description" content={props.description} />
        <meta name="twitter:image" content={meta.image} />
      </Helmet>
  )
}

export default Seo
