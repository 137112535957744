import * as React from "react"
// @ts-ignore
import * as styles from "./style.module.scss"

// data
const links = [
  {
    text: '株式会社プロフィッツ',
    url: 'https://profitz.jp/'
  }
]

// markup
const Footer = () => {
  return (
    <div className={styles.footer}>
      <div className={styles.links}>
        <div>会社情報</div>
        {links.map(link => (
          <div key={link.url}>
            <a
              href={link.url}
              target="_blank"
            >
              {link.text}
            </a>
          </div>
        ))}
      </div>
      <div className={styles.text}>
        ©PROFITZ All Rights Reserved.
      </div>
    </div>
  )
}

export default Footer
