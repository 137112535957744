import * as React from "react"

// props
type Props = {
  width?: number
  height?: number
  color?: string
}

// markup
const IconContactUs = (props: Props) => {
  return (
    <svg width={props.width || 72} height={props.height || 62} viewBox="0 0 72 62" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M72 0.799805L0 27.7912L22.8293 38.1284M72 0.799805L63.8049 55.3569L46.6829 47.8912M72 0.799805L22.8293 38.1284M72 0.799805L37.4634 43.8712M37.4634 43.8712L29.8537 61.0998M37.4634 43.8712L46.6829 47.8912M29.8537 61.0998L22.8293 38.1284M29.8537 61.0998L46.6829 47.8912" stroke={props.color || '#000'}/>
    </svg>
  )
}

export default IconContactUs
